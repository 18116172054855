@font-face {
  font-family: 'komika_handregular';
  src: url('Komika_Hand-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.fontloader {
  font-family: 'komika_handregular';
  color: #444444;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

#demoCanvas{
  width: 100%;
  height: 100%;
  background-color: #FCF1F0;
}

.mainmenu{
  background-color: #000000;
}

.loadingindicator{
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  font-size: 3em;
}

.toolcontainer{
  height: 0px;
}

.toolmenu{
  position: relative;
  background-color: #FD796F;
}

.toolmenu-2{
  position: relative;
  background-color: #FCCDCA;
  border-bottom: 1px solid #FD796F;
}

.btn-brandred {
  color: white;
  background-color: #000000;
}

.btn-brandred:hover {
  color: #F9BF30;
}

.btn-brandred:focus {
  box-shadow: 0 0 2px #ffffff;
}

.btn-brandyellow {
  color: #000000;
  background-color: #F9BF30;
}

.btn-brandyellow:hover {
  background-color: #FAB70F;
}

.btn-brandyellow:focus {
  box-shadow: 0 0 2px #000000;
}